@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --header-height: 60px;
  background-color: #f4f4f4;
}

.container {
  width: 100%;
  max-width: 100%;
}

.home-content {
  padding-left: 12rem;
  padding-right: 12rem;
  margin-top: 9rem;
}

.user-card {
  background-color: white;
  border-radius: 20px;
  border: #e6e6e6 1px solid;
  cursor: pointer;
  max-width: 28rem;
}

.text-input {
  width: 100%;
  padding: 12px 20px;
  margin-top: 2px;
  display: inline-block;
  box-sizing: border-box;
  border: none;
  border-bottom: 2px solid #5d5fef;
}

.text-input:focus {
  outline: none;
}

.chat-input {
  width: 100%;
  padding: 8px 20px;
  margin: 1rem;
  display: inline-block;
  box-sizing: border-box;
  border: none;
  border: 2px solid #5d5fef;
  border-radius: 5px;
}

.chat-input:focus {
  outline: none;
}

.chat-container {
  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.08);
  overflow-y: hidden;
  width: 100%;
}

.chat-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #a44d91;
  border-radius: 8px 8px 0 0;
  color: #fff;
  padding: 1rem 0;
}

.chat-content {
  height: 62.5vh;
  overflow-y: scroll;
}

.chat-footer {
  border-top-width: 2px;
  border-color: rgb(198, 202, 206);
}

.header {
  height: var(--header-height);
  color: white;
  background: #a44d91;
  width: 100%;
  position: fixed;
  top: 0%;
}

.text-pink {
  color: #a44d91;
}

.bg-pink {
  background: #a44d91;
}

.card {
  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.08);
  height: fit-content;
}

.card-body {
  padding: 2rem;
}

.google-button {
  padding: 0.5rem 1rem;
  border-radius: 8px;
  box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.11);
  background: #fff;
  width: 100%;
  display: flex;
  justify-content: center;
}

.text-or {
  position: relative;
  height: 1.5px;
  background-color: #dedede;
  display: inline-block;
  width: 100%;
}

.text-or span {
  padding: 0 1.5rem;
  background-color: #fff;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.label {
  font-weight: 600;
  font-size: 16px;
  color: #5d5fef;
  margin-left: 8px;
}

.label2 {
  font-weight: 600;
  font-size: 16px;
  color: #a44d91;
}

.text-pink {
  color: #a44d91;
}

.slider {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 5px;
  height: 10px;
  width: 100%;
  background-color: #a44d91;
}

.error-message {
  color: #db3c30;
}

/* Loader */
.loader-fill {
  background: linear-gradient(180deg, #a44d91 0%, #6e5793 100%);
  background-attachment: fixed;
  color: #fff;
  width: 100%;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 20;
}
.loader-fill .loader-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  max-width: 100%;
  padding: 0 2rem;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.loader-fill .loader-body .MuiCircularProgress-root {
  width: 2.5rem;
  height: 2.5rem;
}
.loader-fill .loader-body .loader-text {
  font-size: 2rem;
  line-height: 3rem;
  font-weight: 700;
  margin-top: 3.5rem;
  background: linear-gradient(90.07deg, #6a41fb 10.35%, #f49959 92.29%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
.loader-fill .loader-body .loader-text img {
  width: 2rem;
}
/* Loader close*/

/* loader bar */
.loader-bar-line {
  position: absolute;
  top: 2px;
  left: 0;
  width: 100%;
  height: 4px;
  background: #ffffff;
  z-index: 1;
  opacity: 0.9;
  border-radius: 5px;
}
.loader-bar {
  position: relative;
  width: 1077px;
  max-width: 100%;
  height: 8px;
  -webkit-box-reflect: below above 2px linear-gradient(transparent, #0005);
  margin-top: 30px;
}
.loader-bar::before {
  content: "";
  position: absolute;
  inset: 0;
  filter: blur(5px);
  background: linear-gradient(
    90deg,
    #fff,
    #0000ff,
    #ff0000,
    #fff,
    #0000ff,
    #00ff00,
    #fff,
    #ff0000,
    #00ff00,
    #fff,
    #ff0000,
    #0000ff,
    #fff
  );
  animation: animate 14s linear infinite;
  background-size: 400%;
}
.loader-bar::after {
  content: "";
  position: absolute;
  inset: 0;
  filter: blur(5px);
  background: linear-gradient(
    90deg,
    #fff,
    #0000ff,
    #ff0000,
    #fff,
    #0000ff,
    #00ff00,
    #fff,
    #ff0000,
    #00ff00,
    #fff,
    #ff0000,
    #0000ff,
    #fff
  );
  animation: animate 14s linear infinite;
  background-size: 400%;
}
/* loader bar close*/

.float-message {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background: "#007BFF";
  color: "#ffffff";
  border-radius: "50%";
  padding: "10px";
  cursor: "pointer";
  box-shadow: "0 4px 8px rgba(0, 0, 0, 0.1)";
}

.form-image-upload {
  background: #ffffff;
  border-radius: 1.5rem;
  padding: 2.5rem;
  width: 130%;
  margin-left: -15%;
  margin-bottom: 1rem;
}

.upload-image img {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  border-width: 4px;
  border-color: #f4f4f4;
}

.profile-image {
  width: 400px;
  height: 250px;
  border-radius: 1rem;
  border-width: 4px;
  border-color: #f4f4f4;
}

.carousel-dots {
  display: flex;
  justify-content: center;
  padding: 1rem 0;
}

.avatar-image img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border-width: 8px;
  border-color: #fff;
}

.personal-chat-avatar img {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  border-width: 8px;
  border-color: #fff;
}

.header-profile-avatar img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.bg-image {
  background-image: url(../assets/images/profile-bg.png);
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 8px 8px 0 0;
}

.edit-icon {
  width: 20;
  height: 20;
}

/* Pagination style */
.pagination {
  display: flex;
  justify-content: center;
}
.pagination li {
  background: white;
  padding: 5px 6px 4px 6px;
  text-transform: capitalize;
  font-size: 23px;
}
.pagination li.active {
  background: var(--bs-primary);
  color: #a44d91;
}
/* Pagination style end*/

/* Responsive design */
@media (max-width: 1280px) {
  .chat-container {
    width: 70%;
  }
}

@media (max-width: 768px) {
  .loader-fill .loader-body .loader-logo img {
    width: 20%;
    margin-left: 40%;
  }
  .chat-container {
    width: 100%;
  }
}

@media (max-width: 680px) {
  .user-card {
    width: 80%;
    background-color: white;
    border-radius: 20px;
    border: #e6e6e6 1px solid;
    cursor: pointer;
    margin-left: 10%;
  }
  .home-content {
    padding: 0%;
  }
}

@media (max-width: 481px) {
  .user-card {
    width: 80%;
    background-color: white;
    border-radius: 20px;
    border: #e6e6e6 1px solid;
    cursor: pointer;
    margin-left: 10%;
  }
  .home-content {
    padding: 0%;
    margin-top: 12rem;
  }
}

@media (max-width: 375px) {
  .loader-fill .loader-body .loader-logo img {
    width: 40%;
    margin-left: 30%;
  }
  .chat-container {
    width: 80%;
  }
  .user-card {
    width: 60%;
    background-color: white;
    border-radius: 20px;
    border: #e6e6e6 1px solid;
    cursor: pointer;
    margin-left: 20%;
  }
  .home-content {
    padding: 0%;
    margin-top: 12rem;
  }
  .form-image-upload {
    background: #ffffff;
    border-radius: 1.5rem;
    padding: 2.5rem;
    width: 150%;
    margin-left: -25%;
    margin-bottom: 1rem;
  }
  .profile-image {
    width: auto;
    height: 200px;
  }
}

@keyframes animate {
  0% {
    background-position: 0 0;
  }
  0% {
    background-position: 400% 0;
  }
}

.btn-primary {
  background: #a44d91;
  padding: 0.5rem 1.5rem;
  color: white;
  border-radius: 6px;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-background-clip: text;
}
